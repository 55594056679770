const { OLY_ENABLE_WS, NODE_ENV } = process.env;

function unregisterServiceWorker(callback) {
  if ('serviceWorker' in navigator) {
    return (
      typeof navigator.serviceWorker.getRegistrations === 'function' &&
      navigator.serviceWorker.getRegistrations().then(registrations => {
        if (registrations.length) {
          registrations.forEach(callback);
        }
      })
    );
  }

  return null;
}

if (OLY_ENABLE_WS && NODE_ENV !== 'development' && 'serviceWorker' in navigator) {
  window.onerror = async e => {
    if (e.includes('Unexpected token <')) {
      try {
        await unregisterServiceWorker(registration => registration.unregister());
        setTimeout(() => window.location.reload(true), 1e3);
      } catch (err) {} // eslint-disable-line no-empty
    }
  };

  (async () => {
    const OfflinePluginRuntime = require('offline-plugin/runtime'); // eslint-disable-line global-require

    let timeoutRef = null;

    const reloadApp = () => {
      clearTimeout(timeoutRef);
      window.location.reload(true);
    };

    window.__APP_SW_INSTALLED__ = !!navigator.serviceWorker.getRegistration(window.location.href);

    OfflinePluginRuntime.install({
      onInstalled: () => (window.__APP_SW_INSTALLED__ = true),
      onUpdateReady: () => {
        window.__APP_SW_UPDATING__ = true;
        OfflinePluginRuntime.applyUpdate(() => (timeoutRef = setTimeout(reloadApp, 5e3)));
      },
      onUpdateFailed: reloadApp,
      onUpdated: reloadApp,
    });

    OfflinePluginRuntime.update();
  })();
} else {
  try {
    unregisterServiceWorker(registration => registration.unregister());
  } catch (err) {} // eslint-disable-line no-empty

  window.__APP_SW_INSTALLED__ = true;
}
