import { init as initSentry, configureScope } from '@sentry/browser';

import { ERRORS_VALUE_BLACKLIST, ERRORS_LIB_BLACKLIST } from 'config/sentryBlackList';
import { version } from '../package.json';

import './sw';

const { OLY_PLATFORM, OLY_SENTRY_DSN, NODE_ENV, OLY_ENABLE_SENTRY } = process.env;

if (OLY_ENABLE_SENTRY) {
  initSentry({
    dsn: OLY_SENTRY_DSN,
    environment: NODE_ENV,
    release: version,
    ignoreErrors: [...ERRORS_VALUE_BLACKLIST, ...ERRORS_LIB_BLACKLIST],
  });
}

configureScope(scope => {
  scope.setExtra('platform', OLY_PLATFORM);
});

(async () => {
  await import('./client');
  await import('./main');
})();
